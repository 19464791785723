// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crescendo-js": () => import("./../../../src/pages/crescendo.js" /* webpackChunkName: "component---src-pages-crescendo-js" */),
  "component---src-pages-ehdot-js": () => import("./../../../src/pages/ehdot.js" /* webpackChunkName: "component---src-pages-ehdot-js" */),
  "component---src-pages-evasteseloste-js": () => import("./../../../src/pages/evasteseloste.js" /* webpackChunkName: "component---src-pages-evasteseloste-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inferno-js": () => import("./../../../src/pages/inferno.js" /* webpackChunkName: "component---src-pages-inferno-js" */),
  "component---src-pages-jubileum-js": () => import("./../../../src/pages/jubileum.js" /* webpackChunkName: "component---src-pages-jubileum-js" */),
  "component---src-pages-kharisma-js": () => import("./../../../src/pages/kharisma.js" /* webpackChunkName: "component---src-pages-kharisma-js" */),
  "component---src-pages-lomake-js": () => import("./../../../src/pages/lomake.js" /* webpackChunkName: "component---src-pages-lomake-js" */),
  "component---src-pages-magnifika-js": () => import("./../../../src/pages/magnifika.js" /* webpackChunkName: "component---src-pages-magnifika-js" */),
  "component---src-pages-medium-js": () => import("./../../../src/pages/medium.js" /* webpackChunkName: "component---src-pages-medium-js" */),
  "component---src-pages-testi-js": () => import("./../../../src/pages/testi.js" /* webpackChunkName: "component---src-pages-testi-js" */)
}

